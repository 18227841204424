'use client'

import { TooltipContentProps, TooltipPortal } from '@radix-ui/react-tooltip'
import clsx from 'clsx'
import React, { useState } from 'react'

import { baiJamjuree } from './AppSetup'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './ui/tooltip'

interface TooltipProps extends Omit<TooltipContentProps, 'content'> {
  width?: string
  padding?: string
  textSize?: string
  variant?: 'default' | 'dark'
  content: React.ReactNode
}

export const EthenaTooltip: React.FC<TooltipProps> = ({
  children,
  content,
  padding = 'p-2',
  textSize = 'text-xs',
  variant = 'default',
  width = 'w-[200px]',
  ...props
}) => {
  const [open, setOpen] = useState(false)
  const { className, ...rest } = props
  return (
    <TooltipProvider delayDuration={400}>
      <Tooltip open={open} onOpenChange={setOpen}>
        <TooltipTrigger
          onClick={() => {
            setOpen(!open)
          }}
          onFocus={() => {
            setTimeout(() => setOpen(true), 0)
          }}
          onBlur={() => {
            setOpen(false)
          }}
        >
          {children}
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent
            className={clsx(
              'z-[9999]',
              baiJamjuree.className,
              width,
              padding,
              textSize,
              className,
              variant === 'default' ? 'tooltip' : 'tooltip-dark',
            )}
            {...rest}
          >
            {content}
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  )
}
