'use client'

import { useMemo } from 'react'
import useSWR from 'swr'

import { formatNumber, NumberType } from '..'
import { SUSDE_TOOLTIP } from '../constants/susde-tooltip'
import { FetchError } from '../types'
import { YieldResponse } from '../utils/edge/protocol-and-staking-yield'

export const useSusdeYieldTooltipData = () => {
  const { data: yieldData } = useSWR<YieldResponse, FetchError>('/api/yields/protocol-and-staking-yield')

  const [yieldStr, tooltip] = useMemo(() => {
    let yieldStr = undefined
    let lastUpdated = undefined

    if (yieldData?.stakingYield) {
      yieldStr = formatNumber(Math.round(yieldData.stakingYield.value), NumberType.NoDecimals) + '%'
      lastUpdated = yieldData.stakingYield.lastUpdated
    }

    let tooltip = SUSDE_TOOLTIP

    if (lastUpdated) tooltip += `\n\nLast Updated: ${lastUpdated}`
    return [yieldStr, tooltip]
  }, [yieldData?.stakingYield])

  return { tooltip, yieldStr }
}
